import { useEffect, useState } from 'react';
import { ThemeUIProvider } from 'theme-ui';
import theme from 'Common/theme';
import IdentityContextProvider from 'Common/context/IdentityContextProvider';
import { getCurrentRoute } from 'Common/functions/Route';
import {
  ApolloProvider,
} from '@apollo/client';
import {
  loadDevMessages,
  loadErrorMessages,
} from '@apollo/client/dev';
import { useRouter } from 'next/router';
import '../src/css/global.css';
import ErrorBoundry from '@/src/js/error-boundry';
import { AppProps } from 'next/app';
import useIsLoggedIn from 'Common/hooks/useIsLoggedIn';
import { getAnalytics, logEvent } from 'firebase/analytics';
import 'Clients/FireBaseClient';
import 'Common/fonts.css';
import '../src/css/FirebaseLogin.css';
import { NextPage } from 'next';
import { initApolloClient } from 'Common/functions/ApolloClient';
import {
  initApolloServerSideClient,
} from 'Common/functions/ApolloServerSideClient';
import { SearchParam } from 'Common/functions/Search';
import 'Common/components/SigninWithGoogle/font.css';
import { ConfigProvider } from 'antd';
import 'react-image-gallery/styles/css/image-gallery.css';
import initFirebaseAuth from 'Common/functions/initFirebaseAuth';
import { AntdConfigProviderTheme } from 'Common/functions/antdTheme';

initFirebaseAuth();

const isSSR = typeof window === 'undefined';

const isDev = process.env.NODE_ENV !== 'production';
if (isDev) {
  loadDevMessages();
  loadErrorMessages();
}

export const AppApolloClient = isSSR ?
  initApolloServerSideClient() :
  initApolloClient();

const App: NextPage<AppProps> = ({ Component, pageProps }) => {
  const [forceLogin, setForceLogin] = useState(false);
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  //const isSkipAuth = Boolean(router.query[SearchParam._SKIP_AUTH]);

  useEffect(() => {
    const route = getCurrentRoute(router.pathname);
    if (route) {
      if (forceLogin) {
        if (route.public) {
          setForceLogin(false);
        }
      } else if (!route.public && isLoggedIn === false) {
        setForceLogin(true);
        logEvent(
          getAnalytics(),
          'internal_page_login_popup',
        );
      }
    }
  }, [router.pathname, isLoggedIn]);

  useEffect(() => {
    logEvent(
      getAnalytics(),
      `navigate_${router.pathname}`,
    );
  }, [router.pathname]);

  useEffect(() => {
    const skippedIndex = router.query[SearchParam.SKIPPED_INDEX];
    if (skippedIndex) {
      logEvent(
        getAnalytics(),
        'autologin_skip_landing_page',
      );

      const q = { ...router.query };
      delete q[SearchParam.SKIPPED_INDEX];
      router.replace({
        pathname: router.pathname,
        query: q,
      });
    }
  }, [router.query]);

  return (
    <>
      <ErrorBoundry>
        <ThemeUIProvider theme={theme}>
          <ConfigProvider
            theme={AntdConfigProviderTheme}
          >
            <ApolloProvider client={AppApolloClient}>
              <IdentityContextProvider>
                <Component {...pageProps} />
              </IdentityContextProvider>
            </ApolloProvider>
          </ConfigProvider>
        </ThemeUIProvider>
      </ErrorBoundry>
    </>
  );
};

export default App;
