import { FirebaseConfig } from 'Clients/FireBaseClient';
import { init } from 'next-firebase-auth';
import { AppCookie, setCookie } from 'Common/functions/Cookie';
import Bugsnag from '@bugsnag/js';

const isDev = process.env.NEXT_PUBLIC_NODE_ENV !== 'production';
const fbClientEmail = process.env.FIREBASE_CLIENT_EMAIL as string;
const firebaseAuthEmulatorHost =
  process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_URL ||
    process.env.FIREBASE_AUTH_EMULATOR_URL;

const initFirebaseAuth = () => {
  init({
    debug: false,
    authPageURL: '/browse',
    appPageURL: '/browse',
    loginAPIEndpoint: '/api/do-login',
    logoutAPIEndpoint: '/api/do-logout',
    onLoginRequestError: (err) => {
      Bugsnag.notify(err as Error);
      setCookie(AppCookie.PeazyIsLoggedIn, '', -1);
    },
    onLogoutRequestError: (err) => {
      Bugsnag.notify(err as Error);
      setCookie(AppCookie.PeazyIsLoggedIn, '', -1);
    },
    firebaseAuthEmulatorHost,
    useFirebaseAdminDefaultCredential: isDev,
    firebaseAdminInitConfig: {
      credential: {
        projectId: FirebaseConfig.projectId as string,
        clientEmail: fbClientEmail,
        privateKey: process.env.FIREBASE_PRIVATE_KEY?.replace(/\\n/g, '\n') as string,
      },
      databaseURL: FirebaseConfig.databaseURL as string,
    },
    firebaseClientInitConfig: FirebaseConfig,
    cookies: {
      name: 'Mimobl',
      domain: process.env.SESSION_COOKIE_DOMAIN,
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: 14 * 3600 * 24 * 1000,
      overwrite: true,
      path: '/',
      sameSite: 'lax',
      secure: !isDev,
      signed: true,
    },
    onVerifyTokenError: (err) => {
      Bugsnag.notify(err as Error);
    },
    onTokenRefreshError: (err) => {
      Bugsnag.notify(err as Error);
    },
  });
};

export default initFirebaseAuth;
