import Bugsnag from '@bugsnag/js';
import React, { ErrorInfo } from 'react';

class ErrorBoundry extends React.Component<Record<string, unknown>, Record<string, unknown>> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    /** If we hit an error, reload */
    const rawErrorBoundryReloads = sessionStorage.getItem('error_boundry_reload');
    const errorBoundryReloads = rawErrorBoundryReloads ?
      parseInt(rawErrorBoundryReloads) :
      0;

    Bugsnag.notify(`React error boundry: ${error}, ${JSON.stringify(errorInfo)}`);

    if (errorBoundryReloads < 2 && process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
      sessionStorage.setItem('error_boundry_reload', (errorBoundryReloads+1).toString());
      window.location.reload();
    }
  }

  render(): React.ReactNode {
    return this.props.children as React.ReactNode;
  }
}

export default ErrorBoundry;

