const MERCHANT_API_ENDPOINT = process.env.NEXT_PUBLIC_MERCHANT_API_ENDPOINT;
const BOOKING_API_ENDPOINT = process.env.NEXT_PUBLIC_BOOKING_API_ENDPOINT;
const NOTIFICATION_API_ENDPOINT =
  process.env.NEXT_PUBLIC_NOTIFICATION_API_ENDPOINT;
const V2_GATEWAY_ENDPOINT = process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT;
const GUEST_GATEWAY_ENDPOINT = process.env.NEXT_PUBLIC_GUEST_GATEWAY_ENDPOINT;

export type AppOperationType = 'MerchantAPI' | 'BookingAPI' |
'NotificationAPI' | 'V2Gateway' | 'GuestGateway'
const operationEndpointMap: Record<AppOperationType, string> = {
  MerchantAPI: MERCHANT_API_ENDPOINT as string,
  BookingAPI: BOOKING_API_ENDPOINT as string,
  NotificationAPI: NOTIFICATION_API_ENDPOINT as string,
  V2Gateway: V2_GATEWAY_ENDPOINT as string,
  GuestGateway: GUEST_GATEWAY_ENDPOINT as string,
};

export default operationEndpointMap;
