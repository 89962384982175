
export enum AppRoutePath {
  INDEX_SERVICE_PROVIDER = '/service-provider',
  INDEX_RESIDENTIAL = '/residential',
  INDEX_COMMERCIAL = '/commercial',
  INDEX = '/',
  SIGNUP = '/signup',
  CUSTOMER_SIGNUP = '/customer-signup',
  BUSINESS_SIGNUP = '/business-signup',
  SERVICE_PROVIDER_SIGNUP = '/service-provider-signup',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  DASHBOARD = '/dashboard',
  SIGNUP_COMPLETE = '/signup-complete',
  HOME = '/home',
  PARTNER = '/partner',
  REGISTER = '/register',
  APPOINTMENT_DETAILS = '/appointment/[eventUid]',
  BUSINESS_SERVICE_PROVIDER_DETAILS = '/profile/[uid]',
  BOOK = '/book/[eventUid]/[stage]/[widgetStage]',
  STRIPE_OAUTH_CALLBACK = '/stripe-oauth-callback',
  CUSTOMER_SIGNIN = '/customer-signin',
  INDEX_CUSTOMER = '/customer',
  APPOINTMENT_REQUEST_WIDGET_V2_ENTER_CUSTOMER_INPUTS = '/v2/request-appointment/[appointmentCartUid]/[bookingCartUid]/enter-customer-inputs',
  APPOINTMENT_REQUEST_WIDGET_V2_SELECT_START_DATE_TIME = '/v2/request-appointment/[appointmentCartUid]/[bookingCartUid]/select-start-date-time',
  APPOINTMENT_REQUEST_WIDGET_V2_CONFIRM = '/v2/request-appointment/[appointmentCartUid]/[bookingCartUid]/confirm',
  BOOKING_CONFIRMATION = '/booking-confirmation',
  FAQS_PAGE = '/faq',
  BROWSE_SERVICE_TYPES = '/browse',
  BROWSE_SERVICE_PROVIDERS = '/providers/[serviceType]',
  BUSINESS_BROWSE_SERVICE_TYPES = '/business/browse',
  BUSINESS_BROWSE_SERVICE_PROVIDERS = '/business/providers/[serviceType]',
  SERVICE_PROVIDER = '/provider/[serviceProviderOwnerUid]',
  CATCH_ALL = '/[[...catchall]]',
  ANONYMOUS_SIGNIN = '/anonymous-signin',
  API_SUBMIT_APPOINTMENT_CART_AND_BOOKING_CART = '/api/submit-appointment-cart-and-booking-cart/[locationUid]/[appointmentCartUid]/[bookingCartUid]',
  API_SUBMIT_BOOKING_CART = '/api/submit-booking-cart/[bookingCartUid]',
  LINK_PAYMENT_METHOD = '/link-payment-method',
  API_ENSURE_CARTS = '/api/ensure-carts/[serviceProviderUid]/[serviceOfferingUid]',
  API_ENSURE_BOOKING_CART = '/api/ensure-booking-cart/[appointmentUid]',
  API_ENSURE_INDIVIDUAL_LOCATION = '/api/ensure-individual-location/[appointmentUid]',
  BOOK_V2_SELECT_SERVICE_OFFERING = '/v2/book/[bookingCartUid]/select-service-offering',
  BOOK_V2_ENTER_CUSTOMER_INPUTS = '/v2/book/[bookingCartUid]/enter-customer-inputs',
  BOOK_V2_SELECT_START_DATE_TIME = '/v2/book/[bookingCartUid]/select-start-date-time',
  BOOK_V2_CONFIRM = '/v2/book/[bookingCartUid]/confirm',
}

export enum RedirectState {
  NOT_FOUND = '404',
  NO_LOCATION_SUBSCRIPTIONS = 'nls',
}

export enum AppRouteSearch {
  View = 'view',
}

export type AppRoute = {
  route: AppRoutePath[keyof AppRoutePath];
  public?: boolean;
  header?: boolean;
  hideDashboardButton?: boolean;
  headerActions?: boolean;
}

