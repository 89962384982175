export enum SearchParam {
    APPOINTMENT_UID = 'auid',
    VIEW = 'view',
    LOCATION_SUBSCRIPTION_INVITE = 'lsi',
    BOOKING_FORM_SERVICE_OFFERING_UID = 'bfsou',
    BOOKING_FORM_START_DATE_TIME = 'bfsdt',
    BOOKING_FORM_DATA_BASE_64 = 'bd',
    EAGER_BOOKING_FORM_DATA_BASE_64 = 'ebd',
    BOOKING_WIDGET_STAGE = 'stage',
    APPOINTMENT_REQUEST_WIDGET_STAGE = 'arws',
    APPOINTMENT_REQUEST_FORM_CUSTOMER_INPUT_SELECTIONS = 'arfcis',
    APPOINTMENT_REQUEST_FORM_CUSTOMER_INPUT_GROUP_SELECTIONS = 'arfcigs',
    BOOKING_PAGE_STAGE_INDEX = 'bpsi',
    APPOINTMENT_REQUEST_FORM_SERVICE_AREA_UID = 'arfsau',
    IS_DEBUG_MODE = 'debug',
    BOOKING_PAGE_MASQUERADE_AS = 'bpma',
    BOOKING_PAGE_DEFAULT_STAGE = 'bpds',
    CONFIRMATION_PAGE_EVENT_UID = 'cpeu',
    CONFIRMATION_PAGE_SERVICE_PROVIDER_UID = 'cpspu',
    CONFIRMATION_PAGE_LOCATION_UID = 'cplu',
    SIGNUP_MODAL_OPEN = 'smo',
    SIGNUP_MODAL_ACCOUNT_TYPE = 'smat',
    _SKIP_AUTH = '_skipAuth',
    RETURN_TO = 'returnTo',
    SKIPPED_INDEX = 'si',
    FB_ACTION_CODE = 'oobCode',
    FB_MODE = 'mode',
    BOOKING_WIDGET_FORCE_EDIT = 'bwfe',
    BROWSE_SERVICE_PROVIDERS_LAT = 'bspla',
    BROWSE_SERVICE_PROVIDERS_LNG = 'bspln',
    SearchParam = 'SearchParam',
    UNAUTH_REDIRECT_TO = 'uart',
    POST_SIGNUP_MIGRATE_ANONYMOUS_ACCOUNT_ID_TOKEN = 'psmaait',
    TIMEZONE = 'tz',
    BROWSE_PAGE_DEFAULT_LOCATION_UID = 'bpdlu',
    FEATURE_FLAG_APPOINTMENT_REQUEST_FLOW_V2 = 'ffarfv2',
}
