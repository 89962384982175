import React, { useState, useEffect, useReducer } from 'react';
import {
  useV2Gateway_GetUserQuery,
  V2Gateway_GetIdentityQueryHookResult as GetIdentityQueryHookResult,
  useV2Gateway_GetIdentityQuery,
  V2Gateway_GetUserQueryHookResult,
} from 'Generated/graphql';
import IdentityContext from '../IdentityContext';
import Bugsnag from '@bugsnag/js';
import { AccountType } from 'Common/functions/AccountType';
import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics';
import { getEmailIsAnonymous } from 'Common/hooks/useIsAnonymous';

type dispatchAction = Partial<Omit<IdentityContext, 'dispatch'>>

export type IdentityContext = {
  readonly dispatch: React.Dispatch<dispatchAction>;
  ownerUid: string | undefined;
  getIdentityQuery: GetIdentityQueryHookResult;
  accountType: AccountType | null;
  fullName: string | null;
  loggedIn: boolean | null;
  emailAddress: string | null;
  country: 'CA' | 'US' | 'unknown' | null;
  getUserQuery: V2Gateway_GetUserQueryHookResult;
  providerId: string | null;
  phoneNumber: string | null;
  isAnonymous: boolean | null;
}

const IdentityContextProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const [_state, _setState] = useState(1);
  const getIdentityQuery = useV2Gateway_GetIdentityQuery({
    fetchPolicy: 'network-only',
  });
  const reducer = (
    state: IdentityContext,
    action: dispatchAction,
  ): IdentityContext => {
    _setState(_state + 1);
    return {
      ...state,
      ...action
    };
  };
  const initialState: Omit<
    IdentityContext, 'dispatch' | 'getIdentityQuery' | 'getUserQuery'
  > = {
    ownerUid: undefined,
    accountType: null,
    fullName: null,
    loggedIn: null,
    emailAddress: null,
    country: null,
    providerId: null,
    phoneNumber: null,
    isAnonymous: null,
  };
  const [state, dispatch] = useReducer<
    React.Reducer<IdentityContext, dispatchAction>
  >(reducer, initialState as IdentityContext);
  const getUserQuery = useV2Gateway_GetUserQuery({
    fetchPolicy: 'network-only',
  });

  const resetState = () => {
    dispatch({
      loggedIn: false,
      accountType: null,
      fullName: null,
      emailAddress: null,
      country: null,
      ownerUid: undefined,
      providerId: null,
    });
  };

  useEffect(() => {
    const { data, error } = getIdentityQuery;
    if (error) {
      resetState();
    } else if (data?.getIdentity) {
      const identity = data.getIdentity;
      dispatch({
        ownerUid: identity.uid,
        loggedIn: true,
        fullName: identity.fullName,
        emailAddress: identity.emailAddress,
        providerId: identity.providerId,
      });
    }
  }, [getIdentityQuery.data, getIdentityQuery.error]);

  useEffect(() => {
    const { data, error } = getUserQuery;
    if (error) {
      resetState();
    }

    if (data?.getUser) {
      const isAnon = getEmailIsAnonymous(data.getUser.emailAddress);

      dispatch({
        accountType: data.getUser.accountType,
        country: data.getUser.country as IdentityContext['country'],
        phoneNumber: data.getUser.phoneNumber,
        emailAddress: data.getUser.emailAddress,
        isAnonymous: isAnon,
      });
    }
  }, [getUserQuery.data, getUserQuery.error]);

  useEffect(() => {
    if (Bugsnag.isStarted()) {
      Bugsnag.addMetadata('identity', state);
    }

    const w = window as Window &
      typeof globalThis &
    { hj: (s: string, u: string, o: Record<string, unknown>) => void };
    if (state.ownerUid) {
      if (w.hj) {
        w.hj('identify', state.ownerUid, state);
      }

      setUserId(getAnalytics(), state.ownerUid);
      setUserProperties(getAnalytics(), {
        emailAddress: state.emailAddress,
        name: state.fullName,
        accountType: state.accountType,
      });
    }
  }, [state]);

  return (
    <IdentityContext.Provider value={{
      ...state,
      getIdentityQuery,
      getUserQuery,
      dispatch,
    }}>
      {children}
    </IdentityContext.Provider>
  );
};

export default IdentityContextProvider;
