import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { message } from 'antd';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { OperationDefinitionNode } from 'graphql';
import { SUPPORT_EMAIL } from 'Copy';
import { getCookie, AppCookie } from 'Common/functions/Cookie';
import APIOperationMap, { AppOperationType } from '@/src/js/APIOperationMap';
import { parse } from 'cookie';

const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY;
const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;
const BUGSNAG_RELEASE_STAGE = process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE;
const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV;

const isSSR = typeof window === 'undefined';

const publicOps = [
  'V2Gateway_SigninPartner',
  'V2Gateway_Login',
  'V2Gateway_Signin',
  'PublicGateway_GetSessionCookieForIdentity',
  'V2Gateway_GetAliasByFrom',
  'V2Gateway_GetLocation',
  'V2Gateway_Signout'
];

export const setAppApolloClientContextLink = setContext((request, context) => {
  const operationPrefix = request.operationName?.split('_')[0];
  const reRoute = operationPrefix ?
    APIOperationMap[operationPrefix as AppOperationType] :
    APIOperationMap[request.operationName as AppOperationType];
  if (reRoute) {
    context.uri = reRoute;
  }
  return context;
});

export const initApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  if (NODE_ENV === 'production' && BUGSNAG_API_KEY) {
    Bugsnag.start({
      apiKey: BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact],
      appVersion: APP_VERSION,
      enabledReleaseStages: ['production', 'staging'],
      releaseStage: BUGSNAG_RELEASE_STAGE,
    });
  }

  const retryLink = new RetryLink({
    attempts: {
      // Don't retry expired session ops
      retryIf: (
        unusedError,
        { operationName },
      ) => operationName !== 'V2Gateway_checkSessionValid' &&
      operationName !== 'V2Gateway_GetIdentity' &&
        operationName !== 'V2Gateway_Login'
    }
  });

  const authLink = new ApolloLink((operation, forward) => {
    const optName = operation.operationName;
    const isPublicOp = /^GuestGateway.*/.test(optName) ||
        publicOps.includes(optName);
    if (isPublicOp) {
      return forward(operation);
    } else {
      const headers = operation.getContext()['headers'];
      const cookies = headers ? parse(headers['cookie']) : null;
      const sessionCookie = cookies ?
        cookies[AppCookie.MimoblSession] :
        null;
      const isSkipAuthCookie = getCookie(AppCookie.MimoblSkipAuth);

      const isLoggedIn = isSSR ?
        Boolean(sessionCookie) :
        getCookie(AppCookie.PeazyIsLoggedIn) !== null ||
      Boolean(isSkipAuthCookie);

      if (isLoggedIn) {
        return forward(operation);
      }
    }

    if (NODE_ENV !== 'production') {
      console.log('Skipping operation', optName);
    }

    return null;
  });

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GATEWAY_API_URI,
    credentials: 'include',
  });

  const createErrorLink = (): ApolloLink => {
    return onError((err) => {
      const { graphQLErrors, networkError, operation } = err;
      Bugsnag.addMetadata('GraphQL', { operation });
      console.log(operation, graphQLErrors, networkError);
      if (graphQLErrors) {
        let unauthError = false;
        graphQLErrors.forEach(err => {
          const { message: errMsg, path } = err;
          switch (err.extensions?.code) {
          case 'UNAUTHENTICATED':
            unauthError = true;
            break;
          default: {
            Bugsnag.notify(
              `[GraphQL error]: Message: ${errMsg}, error: \
                  ${JSON.stringify(err)}, Path: ${path}`
            );
            const hasMutation = operation.query.definitions.some(
              d => (d as OperationDefinitionNode).operation === 'mutation'
            );
            if (hasMutation && !isSSR) {
              const lastSawErr = localStorage.getItem('lastSawError');
              // prevent error message spam when multiple queries fail
              if (
                !lastSawErr || parseInt(lastSawErr) + 10000 < Date.now()
              ) {
                localStorage.setItem('lastSawError', Date.now().toString());
                message.error(
                  `An error occured and our team has been notified. 
                    Please contact ${SUPPORT_EMAIL} if you require assistance.`,
                  5
                );
              }
            }
            break;
          }
          }
        });

        if (unauthError) {
          return;
        }
      }

      if (networkError) {
        const isUnauthenticatedError = networkError.stack &&
          /Received status code 401/.test(networkError.stack);
        // The 401 response for an expired session will trigger this
        // codepath, but we can ignore it because reauth is handled deeper in
        // app
        if (
          operation.operationName === 'V2Gateway_checkSessionValid' ||
          operation.operationName === 'V2Gateway_GetIdentity' ||
          isUnauthenticatedError
        ) {
          return;
        }
        Bugsnag.notify(`[GraphQL Network error]: ${networkError}`);
      }
    });
  };

  const uploadLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT,
    credentials: 'include',
  });

  const link = createErrorLink()
    .concat(setAppApolloClientContextLink)
    .concat(authLink)
    .concat(retryLink.split(
      op => op.getContext().uri === process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT,
      uploadLink,
      httpLink,
    ));

  const cache = new InMemoryCache();

  const client = new ApolloClient({
    cache,
    link,
    ssrMode: isSSR,
  });

  return client;
};


