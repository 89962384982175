export enum AppCookie {
  ClosedJoinSharedLocationPrompt = 'cjsp',
  PeazyIsLoggedIn = 'mimoblaili',
  MimoblSession = 'mimobl_session',
  MimoblSkipAuth = '_mimoblsa',
}

export const setCookie = (
  name: AppCookie,
  value: string,
  ttlDays?: number,
): void => {
  const d = new Date;
  d.setTime(d.getTime() + (ttlDays || 10000) * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

export const getCookie = (name: AppCookie): string | null => {
  const key = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(key) === 0) {
      return c.substring(key.length, c.length);
    }
  }
  return null;
};

