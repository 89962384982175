import { AppRoute, AppRoutePath } from './routes';

const pages: AppRoute[] = [
  {
    route: AppRoutePath.INDEX,
    public: true,
  },
  {
    route: AppRoutePath.CATCH_ALL,
    public: true,
  },
  {
    route: AppRoutePath.INDEX_SERVICE_PROVIDER,
    public: true,
  },
  {
    route: AppRoutePath.INDEX_RESIDENTIAL,
    public: true,
  },
  {
    route: AppRoutePath.DASHBOARD,
    header: false
  },
  {
    route: AppRoutePath.APPOINTMENT_DETAILS,
    header: false
  },
  {
    route: AppRoutePath.BUSINESS_SERVICE_PROVIDER_DETAILS,
  },
  {
    route: AppRoutePath.PARTNER,
    public: true,
    hideDashboardButton: true
  },
  {
    route: AppRoutePath.BOOK,
    header: true,
    public: false,
  },
  {
    route: AppRoutePath.HOME,
    header: true,
    public: false,
  },
  {
    route: AppRoutePath.STRIPE_OAUTH_CALLBACK,
  },
  {
    route: AppRoutePath.CUSTOMER_SIGNIN,
    public: true,
    hideDashboardButton: true,
  },
  {
    route: AppRoutePath.INDEX_CUSTOMER,
    public: true,
    hideDashboardButton: true,
  },
  {
    route: AppRoutePath.INDEX_COMMERCIAL,
    public: true,
  },
  {
    route: AppRoutePath.BOOKING_CONFIRMATION,
    header: true,
    public: false,
  },
  {
    route: AppRoutePath.BROWSE_SERVICE_PROVIDERS,
    public: false,
  },
  {
    route: AppRoutePath.SERVICE_PROVIDER,
    public: false,
  },
  {
    route: AppRoutePath.SIGNUP,
    public: true,
  },
  {
    route: AppRoutePath.LOGIN,
    public: true,
  },
  {
    route: AppRoutePath.BROWSE_SERVICE_TYPES,
    public: true,
  },
  {
    route: AppRoutePath.REGISTER,
    public: true
  },
  {
    route: AppRoutePath.RESET_PASSWORD,
    public: true
  },
  {
    route: AppRoutePath.FORGOT_PASSWORD,
    public: true
  },
];

export default pages;

