import { useContext, useEffect, useState } from 'react';
import IdentityContext from 'Common/context/IdentityContext';

export const getEmailIsAnonymous = (emailAddress: string) =>
  /^anon\+.+@bookpeazy.com$/.test(emailAddress);

const useIsAnonymous = (): boolean => {
  const {
    emailAddress,
  } = useContext(IdentityContext);
  const [isIsAnonymous, setIsIsAnonymous] = useState(
    emailAddress ? getEmailIsAnonymous(emailAddress) : false,
  );

  useEffect(() => {
    if (emailAddress) {
      setIsIsAnonymous(getEmailIsAnonymous(emailAddress));
    }
  }, [emailAddress]);

  return isIsAnonymous;
};

export default useIsAnonymous;

